<script>
    import Swal from "sweetalert2";
    import Notification from "@/services/Notification";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
    import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
 
    export default {
        components:{
            flatPickr,
        },
        props: {
        },
        data() {
            return {
                showModal: false,
                tryingToEdit: false,
                tryingToSubmit: false,
                notifTypes: false,
                notifLanguages: ['NL','EN'],
                datetimeconfig: {
                    enableTime: true,
                    time_24hr: true,
                    dateFormat: "Y-m-d H:i",
                    minDate: "today",
                    minuteIncrement: 1,
                    defaultDate: new Date(),
                    plugins: [new ConfirmDatePlugin({})]
                },
                showLanguage: process.env.VUE_APP_MULTILANGUAGE_NOTIFICATION,
                notifData: {
                    type: '',
                    message: '',
                    note: '',
                    recipient: 'ALL',
                    schedule_time: '',
                    language: ''
                }
            };
        },
        methods: {
            refreshNotificatinData() { 
                this.$emit('refreshScreen') //event from parent
            },
            addNotification(){
                //submit
                console.log(this.notifData)
                this.tryingToSubmit = true;
                let formData = new FormData();
                formData.append('type', this.notifData.type);
                formData.append('message', this.notifData.message);
                formData.append('note', this.notifData.note);
                formData.append('recipient', this.notifData.recipient);
                formData.append('schedule_time', this.notifData.schedule_time);
                formData.append('language', this.notifData.language);

                Notification.create(formData)
                .then((response) => {
                    this.tryingToSubmit = false;
                    const res = response.data.success ? response.data.success : false;
                    const error = response.data.error ? response.data.error : '';
                    if(res){
                        Swal.fire("OK!", "Bulk Push Notification Created !", "success")
                        this.refreshNotificatinData();
                        this.closeModal();
                    }else{
                        Swal.fire("Fail!", error, "warning")
                    }
                })
                .catch(error => {
                    this.tryingToSubmit = false;
                    const resData = error.response.data.error ? error.response.data.error : 'Failed';
                    const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                    Swal.fire("Fail!", errorMsg, "warning")
                })
            },

            getNotificationTypes() {
                this.notifTypes =  [];
                Notification.getNotificationTypes()
                .then((response) => {
                    //console.log(response)
                    this.notifTypes = response.data.data
                })
            },

            closeModal() {
                this.showModal = false;
                this.notifData.type = '';
                this.notifData.message = '';
                this.notifData.note = '';
                this.notifData.recipient = 'ALL';
                this.notifData.schedule_time = '';
                this.notifData.language = '';
            },
            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Add Success",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },
        },
        created() {
            this.getNotificationTypes()
        },
    };
</script>



<template>
    <b-modal
        id="add_new_push_notification"
        size="md"
        v-model="showModal"
        title="Bulk Push Notification"
        title-class="text-black font-18"
        body-class="p-3"
        hide-footer
    >
    <form @submit.prevent="addNotification">
        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="radio-inline me-4"><input type="radio" id="recipient-all" name="recipient" value="ALL" v-model="notifData.recipient"> All Users</label> 
                    <label class="radio-inline me-4"><input type="radio" id="recipient-ios" name="recipient" value="IOS" v-model="notifData.recipient"> IOS Users</label>
                    <label class="radio-inline me-4"><input type="radio" id="recipient-android" name="recipient" value="ANDROID" v-model="notifData.recipient"> Android Users</label>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Type</label>
                    <select v-model="notifData.type" class="form-control form-select" name="type" type="select" >
                        <option v-for="item in notifTypes" :key="item.type_code" :value="item.type_name">
                            {{item.type_name }} 
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label label-for">Message</label>
                    <b-form-textarea  v-model="notifData.message"   rows="3" ></b-form-textarea>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Quick note</label>
                    <b-form-input  v-model="notifData.note"></b-form-input>
                </div>
                <div class="mb-3" v-if="showLanguage=='true'">
                    <label class="control-label form-label">Language</label>
                    <select v-model="notifData.language" class="form-control form-select" name="type" type="select" >
                        <option value="">All</option>
                        <option value="NL">NL</option>
                        <option value="EN">EN</option>
                    </select>
                </div>

                <label class="form-label mb-2">Date From</label>
                <flat-pickr
                        v-model="notifData.schedule_time"
                        :config="datetimeconfig"
                        class="form-control mb-2"
                        name="date_from"
                />
                

            </div>
        </div>
        <div class="text-end pt-5 mt-3">
            <b-button variant="light" @click="closeModal" >Close</b-button>
            <b-button type="submit" variant="primary" class="ms-3" @click="addNotification" :disabled="tryingToSubmit">
                Submit
            </b-button>
        </div>
      </form>
    </b-modal>
 
</template>